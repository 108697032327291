//  Styles.css - global style sheet
//
//    Defines global classes and styles the base page grid
//
@import 'theme';

// Reset styles
//
// http://meyerweb.com/eric/tools/css/reset/
//   v2.0 | 20110126
//   License: none (public domain)
//

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  outline-color: $edx-clr-field-focus-border !important;
}

div.tile:focus-visible{
  outline: 2px solid $edx-clr-field-focus-border !important;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// global styles

body {
  @include user-select(none);
  font-family: $edx-font-regular;
  overflow: hidden;
}

.edx_circle {
  @include border-radius(50%);
  border: solid 1px darkgray;
}

.edx_hidden {
  display: none;
}

.edx_transparent {
  opacity: 0.0;
}

.edx_noscroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.edx-form {
  padding: .5rem;
}

// fonts
@font-face {
  font-family: OpenText Sans Light;
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/OpenTextSans_W_Lt.woff2) format("woff2"), url(./assets/fonts/OpenTextSans_W_Lt.woff) format("woff");
}

@font-face {
  font-family: OpenText Sans;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/OpenTextSans_W_Rg.woff2) format("woff2"), url(./assets/fonts/OpenTextSans_W_Rg.woff) format("woff");
}

@font-face {
  font-family: OpenText Sans;
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/OpenTextSans_W_Bd.woff2) format("woff2"), url(./assets/fonts/OpenTextSans_W_Bd.woff) format("woff");
}

// generic styles for form elements

label {
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

label, .checkboxlabel {
  color: $edx-clr-text-form-label;
  font-family: $edx-font-regular;
  font-size: $edx-text-size-list;
  line-height: 1.75rem;
  position: relative;
  top: 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxlabel {
  left: 0.25rem;
  &.footer {
    width: 15%;
    text-align: center;
  }
}

input {
  background-color: $edx-clr-page-bg;
  font-family: $edx-font-regular;
  font-size: $edx-text-size-list;
  line-height: 1.75rem;
  display: inline-block;
  height: 1.75rem;
  padding: 0 0.5rem;
  @include border-radius(0.188rem);
  border: 1px solid $edx-clr-text-form-label;
  margin: 1px;
  &:focus {
    margin: 0;
    border: 2px solid $edx-clr-field-focus-border;
    outline: none;
    height: 1.75rem;
    line-height: 1.75rem;
  }
  &[type="number"] {
    max-width: 8rem;
  }
  &[type="radio"] {
    height: 1.625rem;
    width: 1.625rem;
  }
}

::-ms-clear {
  display: none;
}

input:-webkit-autofill:hover, input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: $edx-clr-form-focus-border;
  font-style: italic;
  opacity: 0.8;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: $edx-clr-form-focus-border;
  font-style: italic;
  opacity: 0.8;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: $edx-clr-form-focus-border;
  font-style: italic;
  opacity: 0.8;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: $edx-clr-form-focus-border;
  font-style: italic;
  opacity: 0.8;
}

input[type="checkbox"], input.checkbox, input[type="raido"], input.radio {
  width: 0;
  height: 0;
  float: left;
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span, input.checkbox + span, input[type="radio"] + span, input.radio + span  {
  line-height: 1.75rem;
  height: 1.75rem;
  padding-left: 1.5rem;
  color: $edx-clr-text-main;
  background-size: 1.125rem 1.125rem;
  background-repeat: no-repeat;
  background-position: inherit;
  position: relative;
  left: 0;
  padding-bottom: 0.25rem;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    outline: none;
  }
}

.ie_edge input[type="checkbox"] + span, .ie_edge input.checkbox + span, .ie_edge input[type="radio"] + span, .ie_edge input.radio + span  {
  background-size: 1.25rem 1.25rem;
}

input[type="checkbox"] + span, input.checkbox + span {
  background-image: url(./assets/images/checkbox.svg);
  &:hover {
    background-image: url(./assets/images/checkbox_focus.svg);
  }
}

input[type="radio"] + span, input.radio + span  {
  background-image: url(./assets/images/radio.svg);
  &:hover {
    background-image: url(./assets/images/radio_focus.svg);
  }
}

input[type="checkbox"]:checked:not(:disabled) + span, input.checkbox.checked:not(:disabled) + span, input[type="radio"]:checked + span, input.radio.checked + span:not(.disablecheckedradio) {
  &:hover {
    background-size: 1.25rem 1.25rem;
  }
}

.ie_edge input[type="checkbox"]:checked + span:not(.readonly), .ie_edge input.checkbox.checked + span:not(.readonly), .ie_edge input[type="radio"]:checked + span, .ie_edge input.radio.checked + span {
  &:hover {
    background-size: 1.275rem 1.275rem;
  }
}

input[type="checkbox"]:checked + span, input.checkbox.checked + span {
  background-image: url(./assets/images/checkbox_on.svg);
  cursor: pointer;
}

input.checkbox.unchecked + span {
  background-image: url(./assets/images/checkbox_unchecked.svg);
  cursor: pointer;
}

input[type="radio"]:checked + span, input.radio.checked + span {
  background-image: url(./assets/images/radio_on.svg);
}

.readonly input[type="checkbox"] + span, .readonly input.checkbox + span, .readonly input[type="radio"] + span, .readonly input.radio + span {
  color: $edx-clr-text-form-label;
  background-size: 1.125rem 1.125rem;
  background-position: 0.0625rem 0.325rem;
}

.readonly input[type="checkbox"] + span, .readonly input.checkbox + span {
  background-image: url(./assets/images/checkbox_dis.svg);
  cursor: default;
}

.readonly input[type="radio"] + span, .readonly input.radio + span {
  background-image: url(./assets/images/radio_dis.svg);
}

.readonly input[type="checkbox"]:checked + span, .readonly input.checkbox.checked + span, .readonly input[type="radio"]:checked + span, .readonly input.radio.checked + span {
  height: 1.5rem;
}

.readonly input[type="checkbox"]:checked + span, .readonly input.checkbox.checked + span {
  background-image: url(./assets/images/checkbox_on_dis.svg);
  cursor: default;
}

.readonly input[type="radio"]:checked + span, .readonly input.radio.checked + span {
  background-image: url(./assets/images/radio_on_dis.svg);
}

textarea {
  background-color: $edx-clr-page-bg;
  color: $edx-clr-text-main;
  resize: none;
  font-family: $edx-font-regular;
  font-size: $edx-text-size-list;
  line-height: 1.25rem;
  display: inline-block;
  box-sizing: content-box;
  padding: 0.375rem;
  @include border-radius(0.188rem);
  border: 1px solid $edx-clr-text-form-label;
  margin: 1px;
  &:focus {
    border: 2px solid $edx-clr-field-focus-border;
    margin: 0;
    outline: none;
  }
}

select, .edx_select {
  display: inline-block;
  background-color: $edx-clr-page-bg;
  color: $edx-clr-text-main;
  font-family: $edx-font-regular;
  font-size: $edx-text-size-list;
  background-image: url(./assets/images/caret_down24.svg);
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.0rem 1.0rem;
  @include border-radius(0.188rem);
  border: 1px solid $edx-clr-text-form-label;
  padding: 0 0.5rem;
  height: 1.875rem;
  line-height: 1.75rem;
  margin: 1px;
  width: calc(100% - 1.25rem);
  max-width: 18.5rem;
  &:focus {
    border: 2px solid $edx-clr-field-focus-border;
    margin: 0;
    outline: none;
  }
  &.empty {
    color: $edx-clr-form-focus-border;
    font-style: italic;
    opacity: 0.8;
  }
  &.justbutton {
    width: 1rem;
  }
}

select {
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
}

.edx_select {
  cursor: pointer;
  text-align: initial;
  &:focus.disabled, &.disabled {
    border: none;
    padding: 0.125rem 0.5rem 0;
    background-image: none;
    cursor: default;
    background: transparent;
  }
}

button {
  font-family: $edx-font-regular;
  min-width: 5.0rem;
  height: 2.0rem;
  margin: 0 0.25rem;
}

button:not(.mobile), button.oai {
  font-size: 0.875rem;
  line-height: 0.75rem;
  border: 1px solid;
  cursor: pointer;
  @include border-radius(1.0rem);
  color: $edx-clr-text-btn;
  background-color: $edx-clr-btn-bg;
  border-color: $edx-clr-btn-bg;
  &.readonly, &.readonly:hover, &.readonly:active {
    background-color: $edx-clr-btn-disable;
    border-color: $edx-clr-btn-disable;
    cursor: default;
    outline: none;
  }
  &:hover {
    background-color: $edx-clr-btn-hover-bg;
    border-color: $edx-clr-btn-hover-bg;
  }
  &:active {
    background-color: $edx-clr-btn-active-bg;
    border-color: $edx-clr-btn-bg;
  }
  &.secondary {
    color: $edx-clr-btn-bg;
    border-color: $edx-clr-btn-bg;
    background-color: $edx-clr-text-btn;
  }
  &.secondary.readonly {
    background-color: $edx-clr-text-btn;
    border-color: $edx-clr-btn-disable;
    color: $edx-clr-text-dimmed;
    cursor: default;
  }
  &.secondary:not(.readonly):hover {
    color: $edx-clr-btn-hover-bg;
    border-color: $edx-clr-btn-hover-bg;
    @include box-shadow(0px, 0px, 0px, 1px, $edx-clr-btn-hover-bg, inset);
  }
  &.secondary:not(.readonly):active {
    color: $edx-clr-btn-bg;
    border-color: $edx-clr-btn-bg;
  }
  &:focus, &.focus {
    border: 2px solid $edx-clr-field-focus-border;
  }
}
button:not(.oai).mobile {
  text-align: center;
  color: $edx-clr-btn-bg;
  border: none;
  background-color: transparent;
  &.header {
    font-size: 1rem;
  }
  &:not(.header) {
    font-size: 1.25rem;
  }
  &:not(.secondary) {
    font-weight:bold;
  }
  &:active {
    background-color: $edx-clr-btn-active-bg;
  }
  &.readonly, &.readonly:hover, &.readonly:active {
    color: $edx-clr-btn-disable;
  }
}

div.radio-group {
  font-family: $edx-font-regular;
  font-size: $edx-text-size-list;
  display: inline-block;
  background-color: $edx-clr-page-bg;
}

progress {
  opacity: 0;
  padding: 0;
}

.edx-segment {
  @include border-radius(1.25rem);
  @include box-shadow(0, 0, 0, 2px, rgba(0,114,170,1), inset);
  border: 1px solid $edx-clr-btn-bg;
  white-space: nowrap;
  overflow: hidden;
  font-size: $edx-text-size-detail;
  &.disabled {
    opacity: 0.67;
    pointer-events: none;
  }
}

.edx-segment-item {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0;
  color: $edx-clr-btn-bg;
  background-color: $edx-clr-page-bg;
  &.selected {
    background-color: $edx-clr-btn-bg;
    color: $edx-clr-page-bg;
  }
  &:active {
    background-color: $edx-clr-btn-hover-bg;
  }
  &.first {
    @include border-radius-left(1.5rem);
  }
  &.last {
    @include border-radius-right(1.5rem);
  }
}

.edx_hilite {
  font-weight: 400;
  text-decoration: none;
  background-color: $edx-clr-hilite;
  margin-right: 0.25rem;
}

body {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(128, 130, 133, 0);
}

:hover::-webkit-scrollbar-thumb {
  background: rgba(128, 130, 133, 1);
  background-clip: content-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 130, 133, 1);
}

.disableHover {
  outline: none;
  box-shadow: none;
}

.disablecheckedradio {
  color: #757575 !important;
  background-image: url(./assets/images/radio_on_dis.svg) !important;
  cursor: default !important;
}

.disableuncheckedradio {
  color: #757575 !important;
  background-image: url(./assets/images/radio_dis.svg) !important;
  cursor: default !important;
}