//  _theme.scss - appearance parameters that define the theme for the eDocs application

// colors
$edx-clr-home-grad-top: #111B58;
$edx-clr-page-bg: #FFFFFF;
$edx-clr-brand-main: #0072AA;
$edx-clr-breadcrumbs-bg: #ebf1f4;
$edx-clr-text-main: #333333;
$edx-clr-text-label: #555555;
$edx-clr-text-btn: #FFFFFF;
$edx-clr-text-btn-hover: #7B9AB0;
$edx-clr-text-btn-active: #999999;
$edx-clr-text-dimmed: #A9A9A9;
$edx-clr-text-selected: #000000;
$edx-clr-text-form-label: #717171;

$edx-clr-tile-bg: #F7F7F7;
$edx-clr-selected-bg: #BAC9CE;
$edx-clr-open-bg: #D6E1ED;
$edx-clr-hover-bg: #DFE7EA;
$edx-clr-hover-border: #627783;

$edx-clr-list-item-border: #DCDCDC;
$edx-clr-form-focus-border: #666666;
$edx-clr-field-focus-border: #4c9aff;
$popup-border-clr: #A9A9A9;


$edx-clr-hero-bg: #575757;
$edx-clr-menu-separator: #CCCCCC;
$edx-clr-ctrl-bg: #e6e7e8;
$edx-clr-link: #337AB7;

$edx-clr-warning-text: #DF3324;
$edx-clr-warning-bg: rgba($edx-clr-warning-text, 0.1);

$edx-clr-upload-text: #FFFFFF;
$edx-clr-upload-bg: rgba(68, 92, 102, 0.85);


$edx-clr-btn-bg: #232E72;
$edx-clr-btn-hover-bg: #171F4C;
$edx-clr-btn-active-bg: #2E3D98;
$edx-clr-btn-disable: #999999;
$edx-clr-progress-bg: #004a70;

$edx-clr-nav-hover-bg: #00a4d8;

$edx-clr-scrollbar-bg: #BBBBBB;
$edx-clr-scrollbar-hover-bg: #808285;

$edx-clr-text-btn-header-mobile: #EEEEEE;
$edx-clr-text-btn-disabled-mobile: #BAC9CE;
$edx-clr-action-hdr-bg-mobile: #DFE7EA;
$edx-clr-meta-hdr-bg-mobile: #E0E7EA;
$edx-clr-list-header-bg-mobile: #003f67;
$edx-clr-list-footer-bg-mobile: #1173A8;

$edx-clr-home-grad-top-oai: #E8E8E8;
$edx-clr-selected-bg-oai: #DEE6E9;
$edx-clr-btn-bg-oai: #CCCCCC;
$edx-clr-btn-active-bg-oai: #D7D7D7;
$edx-clr-meta-hdr-bg-oai: #F4F4F4;
$edx-clr-app-hdr-bg-oai: #5867AB;

$edx-clr-facet-hdr-bg: #EFF4E9;
$edx-clr-facet-hdr-border: #8CC53E;
$edx-clr-facet-count-bg: #c9f093;

$edx-clr-hilite: #85DDDE;

// dimensions
$edx-dim-tile-width-single: 324px;
$edx-dim-tile-height-single: 324px;
$edx-dim-tile-width-double: 660px;
$edx-dim-tile-height-double: 660px;
$edx-dim-tile-margin: 4px;
$edx-dim-tile-item-height: 2.3rem;
$edx-dim-tile-container-height-oai: 4.375rem;

// text sizes
$edx-text-size-header: 1.8rem;
$edx-text-size-menu: 1.0rem;
$edx-text-size-tile-header: 1.5rem;
$edx-text-size-tile-header-mobile: 1.5rem;
$edx-text-size-window-header: 2.25rem;
$edx-text-size-large: 1.275rem;
$edx-text-size-list: 0.875rem;
$edx-text-size-detail: 0.75rem;
$edx-text-size-small: 0.625rem;
$edx-text-size-hero: 2.0rem;
$edx-text-size-hero-header: 1.125rem;

// animation params
$edx-widget-transition-time: 200ms;

$edx-font-regular: "OpenText Sans", "Helvetica", sans-serif;
$edx-font-light: "OpenText Sans Light", "Helvetica Light", sans-serif;
$edx-font-bold: "OpenText Sans Bold", "Helvetica bold", sans-serif;

// polyfills

@mixin background-gradient() {
background: -webkit-linear-gradient(29deg, rgb(9, 14, 44) 0%, rgb(18, 44, 105) 59%, rgb(7, 141, 179) 100%);
background: -o-linear-gradient(29deg, rgb(9, 14, 44) 0%, rgb(18, 44, 105) 59%, rgb(7, 141, 179) 100%);
background: -ms-linear-gradient(29deg, rgb(9, 14, 44) 0%, rgb(18, 44, 105) 59%, rgb(7, 141, 179) 100%);
background: -moz-linear-gradient(29deg, rgb(9, 14, 44) 0%, rgb(18, 44, 105) 59%, rgb(7, 141, 179) 100%);
background: linear-gradient(61deg, rgb(9, 14, 44) 0%, rgb(18, 44, 105) 59%, rgb(7, 141, 179) 100%);
}

@mixin phone-tile-gradient($leftclr, $rightclr) {
  background: -webkit-linear-gradient(346deg, $leftclr 0%, $rightclr 100%);
  background: -moz-linear-gradient(346deg, $leftclr 0%, $rightclr 100%);
  background: -ms-linear-gradient(346deg, $leftclr 0%, $rightclr 100%);
  background: -o-linear-gradient(346deg, $leftclr 0%, $rightclr 100%);
  background: linear-gradient(104deg, $leftclr 0%, $rightclr 100%);
}

@mixin user-select($allow) {
  -webkit-user-select: $allow;
  -ms-user-select: $allow;
  -moz-user-select: $allow;
  user-select: $allow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-top($radius) {
  -webkit-border-top-left-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius-left($radius) {
  -webkit-border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-radius-right($radius) {
  -webkit-border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin box-shadow($horiz, $vert, $blur, $spread, $color, $inset:'') {
  -webkit-box-shadow: $horiz $vert $blur $spread $color #{$inset};
  -moz-box-shadow: $horiz $vert $blur $spread $color #{$inset};
  -o-box-shadow: $horiz $vert $blur $spread $color #{$inset};
  box-shadow: $horiz $vert $blur $spread $color #{$inset};
}

// mixin styles

@mixin icon-button($imagepath) {
  background-color: $edx-clr-page-bg;
  background-image: url($imagepath);
}

@mixin icon-button-round($imagepath) {
  @include icon-button($imagepath);
  @include border-radius(50%);
  height: 2.625rem;
  width: 2.625rem;
}
